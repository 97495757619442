import {
  SHOW_LIVE_EVENT,
  SHOW_LIVE_EVENT_V2,
  BROADCAST_ERROR,
  UPDATE_EVENT,
  UPDATE_STREAM_KEY,
  SHOW_STREAMING_SOURCE_PROVIDERS,
  UPDATE_STREAMING_SOURCE_PROVIDER
} from "../../actions/broadcast/broadcastActions";

export const broadcast = (
  state = {
    errorResponse: {},
    errorMessage: "",
    liveEvent: {},
    app: {},
    ott: {},
    updated_event: {},
    sourceProviders: {},
    updated_provider: {},
    stream_key: localStorage.getItem("chat_id") || ""
  },
  action
) => {
  switch (action.type) {
    case SHOW_LIVE_EVENT:
      return Object.assign({}, state, {
        ...state,
        liveEvent: action.liveEvent
      });
    case SHOW_LIVE_EVENT_V2:
      return Object.assign({}, state, {
        ...state,
        [action.platform]: action.liveEventV2
      });
    case UPDATE_EVENT:
      return Object.assign({}, state, {
        ...state,
        updated_event: action.info
      });
    case UPDATE_STREAM_KEY:
      return Object.assign({}, state, {
        ...state,
        stream_key: action.key
      });
    case SHOW_STREAMING_SOURCE_PROVIDERS:
      return Object.assign({}, state, {
        ...state,
        sourceProviders: action.sourceProviders
      });
    case UPDATE_STREAMING_SOURCE_PROVIDER:
      return Object.assign({}, state, {
        ...state,
        updated_provider: action.info
      });
    case BROADCAST_ERROR:
      return Object.assign({}, state, {
        ...state,
        errorResponse: action.response,
        errorMessage: action.message.message
      });
    default: {
      return state;
    }
  }
};
