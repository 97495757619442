import { history } from "../../../history";
import axios from "axios";
import _ from "lodash";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

const URL = process.env.REACT_APP_API_URL;

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const SHOW_SINGLE_USER = "SHOW_SINGLE_USER";
export const UPDATE_SINGLE_USER = "UPDATE_SINGLE_USER";

const receiveUsers = users => {
  return {
    type: GET_USERS_SUCCESS,
    isFetching: false,
    users
  };
};

const showSingleUser = user => {
  return {
    type: SHOW_SINGLE_USER,
    user
  };
};

const updateSingleUser = info => {
  return {
    type: UPDATE_SINGLE_USER,
    info
  };
};

export const userListActions = (
  paginate = 20,
  page,
  first_name = "",
  last_name = "",
  email = "",
  role = ""
) => {
  return dispatch => {
    let token = localStorage.getItem("id_token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      baseURL: URL,
      url: "admin/users/index",
      method: "get",
      headers,
      params: {
        page,
        paginate,
        first_name,
        last_name,
        email,
        role
      }
    })
      .then(response => {
        let userList;
        if (response.data) {
          userList = response.data.data;
          dispatch(receiveUsers(userList));
        }
      })
      .catch(err => console.log(err));
  };
};

export const showUser = id => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${URL}admin/users/show/${id}`, {
        headers
      })
      .then(response => {
        let user;
        if (response.data) {
          user = response.data.data;
          dispatch(showSingleUser(user));
        }
      })
      .catch(err => console.log(err));
  };
};

export const singleUserUpdate = (id, newInfo) => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    const dataToSend = new FormData();

    Object.keys(newInfo).forEach(key => {
      if (newInfo[key].name !== undefined) {
        dataToSend.append(key, newInfo[key], newInfo[key].name);
      } else {
        dataToSend.append(key, newInfo[key]);
      }
    });

    dataToSend.append("_method", "PATCH");

    axios
      .post(`${URL}admin/users/update/${id}`, dataToSend, config)
      .then(response => {
        let updatedInfo;
        if (response.data) {
          updatedInfo = response.data.data;
          dispatch(updateSingleUser(updatedInfo));
          history.push(`/users/edit/${id}`);
          if (!_.isEmpty(updatedInfo) && response.data.status === "FOUND") {
            toast.success("User Successfully Updated.", {
              transition: Slide
            });
          }
        }
      })
      .catch(err => console.log(err));
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");

    axios({
      method: "DELETE",
      url: `${URL}admin/users/delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 204) {
        userListActions();
      }
    });
  };
};