import { combineReducers } from "redux";
import auth from "./auth/";
import broadcast from "./broadcast/";
import settings from "./settings/";
import ads from "./marketing-space/";
import users from "./users/";

import customizer from "./customizer/";

const rootReducer = combineReducers({
  auth: auth,
  broadcast: broadcast,
  settings: settings,
  ads: ads,
  users: users,
  customizer: customizer
});

export default rootReducer;
