import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  SHOW_SINGLE_USER,
  UPDATE_SINGLE_USER,
} from "../../actions/users/userActions";

export const userList = (
  state = {
    isFetching: true,
    users: {},
    updatedInfo: {},
    errorMessage: "",
    singleUser: {},
    singleUserUpdated: {},
    allUsers: {}
  },
  action
) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isFetching: true
      });
    case GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        userList: action.users
      });
    case GET_USERS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        errorMessage: action.message
      });
    case SHOW_SINGLE_USER:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        singleUser: action.user
      });
    case UPDATE_SINGLE_USER:
      return Object.assign({}, state, {
        ...state,
        singleUserUpdated: action.info
      });
    default: {
      return state;
    }
  }
};
