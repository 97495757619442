import { UPDATE_USER_SUCCESS } from "../../actions/settings/user-settings-actions";

export const userSettings = (
  state = {
    isUpdating: false,
    updatedInformation: {},
    status: ''
  },
  action
) => {
  switch (action.type) {
    case UPDATE_USER_SUCCESS:
      return Object.assign({}, state, {
        updatedInformation: action.updatedInformation,
        status: action.status
      });
    default: {
      return state;
    }
  }
};
