import { GET_ADS_SUCCESS, GET_UPDATED_AD, ADD_NEW_AD, SHOW_AD } from "../../actions/marketing-space/adsListActions";

export const adsList = (
  state = {
    ads: {},
    newAd: {},
    updatedAd: {},
    adToEdit: {}
  },
  action
) => {
  switch (action.type) {
    case GET_ADS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        ads: action.ads
      });
    case GET_UPDATED_AD:
      return Object.assign({}, state, {
        ...state,
        updatedAd: action.info
      });
    case ADD_NEW_AD:
      return Object.assign({}, state, {
        ...state,
        newAd: action.newAd
      });
    case SHOW_AD:
      return Object.assign({}, state, {
        ...state,
        adToEdit: action.info
      });
    default: {
      return state;
    }
  }
};
