import axios from "axios";
import _ from "lodash";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

const URL = process.env.REACT_APP_API_URL;
const V2URL = process.env.REACT_APP_API_V2_URL;

export const SHOW_LIVE_EVENT = "SHOW_LIVE_EVENT";
export const SHOW_LIVE_EVENT_V2 = "SHOW_LIVE_EVENT_V2";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_STREAM_KEY = "UPDATE_STREAM_KEY";
export const SHOW_STREAMING_SOURCE_PROVIDERS =
  "SHOW_STREAMING_SOURCE_PROVIDERS";
export const UPDATE_STREAMING_SOURCE_PROVIDER =
  "UPDATE_STREAMING_SOURCE_PROVIDER";
export const BROADCAST_ERROR = "BROADCAST_ERROR";

const showLiveEvent = (liveEvent) => {
  return {
    type: SHOW_LIVE_EVENT,
    liveEvent,
  };
};

const showLiveEventV2 = (platform, liveEventV2) => {
  return {
    type: SHOW_LIVE_EVENT_V2,
    platform,
    liveEventV2,
  };
};

const updateEvent = (info) => {
  return {
    type: UPDATE_EVENT,
    info,
  };
};

const showSourceProviders = (sourceProviders) => {
  return {
    type: SHOW_STREAMING_SOURCE_PROVIDERS,
    sourceProviders,
  };
};

const updateSourceProvider = (info) => {
  return {
    type: UPDATE_STREAMING_SOURCE_PROVIDER,
    info,
  };
};

const updateStreamKey = (key) => {
  return {
    type: UPDATE_STREAM_KEY,
    key,
  };
};

const getError = (response, message) => {
  return {
    type: BROADCAST_ERROR,
    response,
    message,
  };
};

export const showLive = () => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${URL}admin/broadcast/show`, {
        headers,
      })
      .then((response) => {
        let liveEvent;

        if (response.data) {
          liveEvent = response.data.data;
          localStorage.setItem("chat_id", liveEvent.stream_key);

          if (liveEvent) dispatch(showLiveEvent(liveEvent));
        }
      })
      .catch((err) => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const showLiveV2 = (platform) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${V2URL}broadcast/streaming/${platform}/live-broadcast`, {
        headers,
      })
      .then((response) => {
        let liveEventV2;
        const provider = platform;

        if (response.data) {
          liveEventV2 = response.data.data;

          if (liveEventV2) dispatch(showLiveEventV2(provider, liveEventV2));
        }
      })
      .catch((err) => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const eventUpdate = (newInfo) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const dataToSend = new FormData();

    Object.keys(newInfo).forEach((key) => {
      if (newInfo[key].name !== undefined) {
        dataToSend.append(key, newInfo[key], newInfo[key].name);
      } else {
        dataToSend.append(key, newInfo[key]);
      }
    });

    dataToSend.append("_method", "PATCH");

    axios
      .post(`${URL}admin/broadcast/update`, dataToSend, config)
      .then((response) => {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        let updatedInfo;
        if (response.status === 200) {
          toast.success("Broadcast event successfully updated.", {
            transition: Slide,
          });

          axios
            .get(`${URL}admin/broadcast/show`, {
              headers,
            })
            .then((response) => {
              let liveEvent;

              if (response.data) {
                liveEvent = response.data.data;
                localStorage.setItem("chat_id", liveEvent.stream_key);

                if (liveEvent) dispatch(showLiveEvent(liveEvent));
              }
            })
            .catch((err) => {
              dispatch(getError(err.response, err));

              console.log(err);
            });

          axios
            .get(`${V2URL}broadcast/streaming/app/live-broadcast`, {
              headers,
            })
            .then((response) => {
              let liveEventV2;

              if (response.data) {
                liveEventV2 = response.data.data;

                if (liveEventV2) dispatch(showLiveEventV2(liveEventV2));
              }
            })
            .catch((err) => {
              dispatch(getError(err.response, err));

              console.log(err);
            });

          if (response.data) {
            updatedInfo = response.data.data;
            dispatch(updateEvent(updatedInfo));
          }
        }
      })
      .catch((err) => {
        dispatch(getError(err.response, err));
        const errors = [];

        Object.keys(err.response.data.message).forEach((key) => {
          errors.push(err.response.data.message[key]);
        });

        _.map(errors[0], (error) => {
          toast.error(error, {
            transition: Slide,
          });
        });
      });
  };
};

export const toggleUpdate = (toggleName) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${URL}admin/notifications/toggle/${toggleName}`, config)
      .catch((err) => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const resetStreamKey = () => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${URL}admin/broadcast/reset-stream-key`, config)
      .then((response) => {
        let streamKey;
        if (response.data) {
          streamKey = response.data.data;
          localStorage.setItem("chat_id", streamKey.stream_key);
          if (streamKey) dispatch(updateStreamKey(streamKey));
        }
      })
      .catch((err) => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const showProvider = () => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${URL}admin/streaming-source-providers/index`, {
        headers,
      })
      .then((response) => {
        let sourceProviders;
        if (response.data) {
          sourceProviders = response.data.data;
          dispatch(showSourceProviders(sourceProviders));
        }
      })
      .catch((err) => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const providerUpdate = (id, newInfo, showSuccess) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const dataToSend = new FormData();

    Object.keys(newInfo).forEach((key) => {
      if (newInfo[key].name !== undefined) {
        dataToSend.append(key, newInfo[key], newInfo[key].name);
      } else {
        dataToSend.append(key, newInfo[key]);
      }
    });

    dataToSend.append("_method", "PATCH");

    axios
      .post(
        `${URL}admin/streaming-source-providers/update/${id}`,
        dataToSend,
        config
      )
      .then((response) => {
        let updatedInfo;
        if (response.data) {
          updatedInfo = response.data.data;
          dispatch(updateSourceProvider(updatedInfo));

          axios
            .get(`${URL}admin/streaming-source-providers/index`, config)
            .then((response) => {
              let sourceProviders;
              if (response.data) {
                sourceProviders = response.data.data;
                dispatch(showSourceProviders(sourceProviders));
              }
            })
            .catch((err) => {
              dispatch(getError(err.response, err));

              console.log(err);
            });

          if (showSuccess) {
            toast.success("Broadcast event successfully updated.", {
              transition: Slide,
            });

            axios
              .get(`${URL}admin/broadcast/show`, config)
              .then((response) => {
                let liveEvent;
                console.log(response);
                if (response.data) {
                  liveEvent = response.data.data;
                  localStorage.setItem("chat_id", liveEvent.stream_key);

                  if (liveEvent) dispatch(showLiveEvent(liveEvent));
                }
              })
              .catch((err) => {
                dispatch(getError(err.response, err));

                console.log(err);
              });

            axios
              .get(`${V2URL}broadcast/streaming/app/live-broadcast`, config)
              .then((response) => {
                let liveEventV2;

                if (response.data) {
                  liveEventV2 = response.data.data;

                  if (liveEventV2) dispatch(showLiveEventV2(liveEventV2));
                }
              })
              .catch((err) => {
                dispatch(getError(err.response, err));

                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};
