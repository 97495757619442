import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import { connect } from "react-redux";
import * as Icon from "react-feather";
import { history } from "../../../history";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = props => {
  const isAuthenticated = props.user.login.isAuthenticated;
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, "/settings")}>
        <Icon.Settings size={14} className="mr-50" />
        <span className="align-middle">Settings</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault();
          if (isAuthenticated) {
            return props.logout();
          }
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none flex-align-center">
              <span className="prim-font regular">{this.props.userName}</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="30"
                width="30"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth,
    settings: state.settings
  };
};

export default connect(mapStateToProps, {})(NavbarUser);
