import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  GET_AUTH_USER,
  ERROR
} from "../../actions/auth/loginActions";

const getUserFromStorage = user => {
  if (localStorage.user) {
    const USER = JSON.parse(localStorage.user);

    return USER;
  }
};

const checkAuth = () => {
  if (localStorage.user) {
    const USER = JSON.parse(localStorage.user);

    if (USER && USER.role === 'admin') {
      return true;
    } else {
      return false;
    }
  }
};

const setRole = () => {
  if (localStorage.user) {
    const USER = JSON.parse(localStorage.user);

    if (USER && USER.role === 'admin') {
      return "admin";
    } else {
      return "user";
    }
  }
};

export const login = (
  state = {
    currentUser: localStorage.getItem("user") ? getUserFromStorage() : {},
    userRole: setRole(),
    isFetching: false,
    isAuthenticated: checkAuth() || false,
    authUser: {},
    errorResponse: {},
    errorMessage: ""
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
        isAuthenticated: checkAuth(),
        userRole: setRole(),
        currentUser: getUserFromStorage()
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      });
    case LOGOUT_SUCCESS:
      localStorage.clear();
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        currentUser: {},
        userRole: ""
      });
    case GET_AUTH_USER:
      return Object.assign({}, state, {
        ...state,
        authUser: action.authUser
      });
    case ERROR:
      return Object.assign({}, state, {
        ...state,
        authUser: {},
        isAuthenticated: false,
        errorResponse: action.response,
        errorMessage: action.message.message
      });
    default: {
      return state;
    }
  }
};
