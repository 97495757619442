import axios from "axios";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

const URL = process.env.REACT_APP_API_URL;

export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";
export const SHOW_AD = "SHOW_AD";
export const GET_UPDATED_AD = "GET_UPDATED_AD";
export const ADD_NEW_AD = "ADD_NEW_AD";

const receiveAds = ads => {
  return {
    type: GET_ADS_SUCCESS,
    isFetching: false,
    ads
  };
};

const updatedAd = info => {
  return {
    type: GET_UPDATED_AD,
    info
  };
};

const showAd = info => {
  return {
    type: SHOW_AD,
    info
  };
};

const addNewAD = newAd => {
  return {
    type: ADD_NEW_AD,
    newAd
  };
};

export const adsListActions = () => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${URL}admin/stories/index`, {
        headers
      })
      .then(response => {
        let adsList;
        if (response.data) {
          adsList = response.data.data.stories;
          dispatch(receiveAds(adsList));
        }
      })
      .catch(err => console.log(err));
  };
};

export const saveAd = adInfo => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    const dataToSend = new FormData();

    Object.keys(adInfo).forEach(key => {
      if (adInfo[key].name !== undefined) {
        dataToSend.append(key, adInfo[key], adInfo[key].name);
      } else {
        dataToSend.append(key, adInfo[key]);
      }
    });

    axios
      .post(`${URL}admin/stories/store`, dataToSend, config)
      .then(response => {
        const headers = { Authorization: `Bearer ${token}` };
        let newInfo;

        if (response.status === 201) {
          toast.success("Ad was successfully updated.", {
            transition: Slide
          });

          axios
            .get(`${URL}admin/stories/index`, {
              headers
            })
            .then(response => {
              let adsList;
              if (response.data) {
                adsList = response.data.data.stories;
                dispatch(receiveAds(adsList));
              }
            })
            .catch(err => console.log(err));

          if (response.data) {
            newInfo = response.data.data;
            dispatch(addNewAD(newInfo));
          }
        }
      })
      .catch(err => console.log(err));
  };
};

export const updateAd = (id, newInfo) => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const dataToSend = new FormData();

    Object.keys(newInfo).forEach(key => {
      dataToSend.append(key, newInfo[key]);
    });

    dataToSend.append("_method", "PATCH");

    axios
      .post(`${URL}admin/stories/update/${id}`, dataToSend, config)
      .then(response => {
        const headers = { Authorization: `Bearer ${token}` };
        let updatedInfo;
        if (response.status === 200) {
          toast.success("Ad was successfully updated.", {
            transition: Slide
          });

          axios
            .get(`${URL}admin/stories/index`, {
              headers
            })
            .then(response => {
              let adsList;
              if (response.data) {
                adsList = response.data.data.stories;
                dispatch(receiveAds(adsList));
              }
            })
            .catch(err => console.log(err));

          if (response.data) {
            updatedInfo = response.data.data;
            dispatch(updatedAd(updatedInfo));
          }
        }
      })
      .catch(err => console.log(err, err.response));
  };
};

export const showSingleAd = id => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${URL}admin/stories/show/${id}`, {
        headers
      })
      .then(response => {
        let ad;
        if (response.data) {
          ad = response.data.data;
          dispatch(showAd(ad));
        }
      })
      .catch(err => console.log(err));
  };
};

export const deleteSingleAd = id => {
  return dispatch => {
    let token = localStorage.getItem("id_token");

    axios({
      method: "DELETE",
      url: `${URL}admin/stories/delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      const headers = { Authorization: `Bearer ${token}` };

      if (response.status === 204) {
        axios
          .get(`${URL}admin/stories/index`, {
            headers
          })
          .then(response => {
            let adsList;
            if (response.data) {
              adsList = response.data.data.stories;
              dispatch(receiveAds(adsList));
            }
          })
          .catch(err => console.log(err));
      }
    });
  };
};