import React from "react";

const Logo = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 701.02 174.62"
  >
    <g id="ec_logo" data-name="Layer 2">
      <g id="EC_-_Master" data-name="EC - Master">
        <path
          id="EC_-_ff671f"
          data-name="EC - #ff671f"
          className="ec_logo_mark text-orange"
          d="M152.26,29.09a87.23,87.23,0,1,0-6.62,123.28A87.37,87.37,0,0,0,152.26,29.09Zm-34,72.78L86.49,62.24,57,101.87l-31.4,0,60.77-80.3,62.28,80.3Z"
        />
        <polygon
          style={{ fill: 'white' }}
          points="148.69 101.92 118.22 101.87 86.48 62.23 57.03 101.87 25.63 101.91 86.4 21.61 148.69 101.92"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M309.7,170.92c-1.81,1-5.63,1.87-10.44,1.87-11.16,0-19.5-7-19.5-20.07,0-12.46,8.41-20.78,20.68-20.78a21.54,21.54,0,0,1,9.39,1.75l-1.3,4.17a18.08,18.08,0,0,0-7.92-1.65c-9.28,0-15.44,5.94-15.44,16.34,0,9.73,5.57,15.91,15.14,15.91a20.68,20.68,0,0,0,8.4-1.64Z"
        />
        <polygon
          className="ec_logo_letter mobile-hide text-white"
          points="350.63 132.58 350.63 149.14 369.77 149.14 369.77 132.58 374.94 132.58 374.94 172.15 369.77 172.15 369.77 153.59 350.63 153.59 350.63 172.15 345.46 172.15 345.46 132.58 350.63 132.58"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M418.26,132.58v23.48c0,8.82,3.94,12.57,9.23,12.57,5.81,0,9.63-3.87,9.63-12.57V132.58h5.17v23.14c0,12.15-6.41,17.07-15,17.07-8.11,0-14.21-4.56-14.21-16.89V132.58Z"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M503.29,161.3c-1-4.11-2.83-6.94-5.82-8v-.18c4.11-1.42,7.47-4.87,7.47-10a9.91,9.91,0,0,0-3.12-7.53c-2.47-2.21-6-3.28-11.51-3.28a54.07,54.07,0,0,0-9.8.88v39h5.11V155h4.8c4.66.18,6.83,2.24,8,7.69,1.06,4.95,1.95,8.34,2.59,9.46h5.28C505.52,170.63,504.59,166.81,503.29,161.3Zm-12.45-10.17h-5.22V136.75a21.61,21.61,0,0,1,5-.47c5.41,0,9.1,2.23,9.1,7.34C499.76,148.14,496.3,151.13,490.84,151.13Z"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M569.22,170.92c-1.83,1-5.64,1.87-10.45,1.87-11.16,0-19.51-7-19.51-20.07,0-12.46,8.41-20.78,20.68-20.78a21.54,21.54,0,0,1,9.39,1.75l-1.3,4.17a18.06,18.06,0,0,0-7.91-1.65c-9.28,0-15.44,5.94-15.44,16.34,0,9.73,5.58,15.91,15.14,15.91a20.76,20.76,0,0,0,8.41-1.64Z"
        />
        <polygon
          className="ec_logo_letter mobile-hide text-white"
          points="610.14 132.58 610.14 149.14 629.27 149.14 629.27 132.58 634.44 132.58 634.44 172.15 629.27 172.15 629.27 153.59 610.14 153.59 610.14 172.15 604.98 172.15 604.98 132.58 610.14 132.58"
        />
        <polygon
          className="ec_logo_letter mobile-hide text-white"
          points="271.42 26.29 222.91 26.29 222.91 46.4 267.44 46.4 267.44 62.91 222.91 62.91 222.91 87.36 273.66 87.36 273.66 104.49 204.23 104.49 204.23 9.4 271.42 9.4 271.42 26.29"
        />
        <rect
          className="ec_logo_letter mobile-hide text-white"
          x="281.15"
          y="9.53"
          width="17.72"
          height="94.96"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M371,60.49A35.49,35.49,0,0,0,365.59,46a27.85,27.85,0,0,0-11.7-10.41A37.25,37.25,0,0,0,338,32.24q-14.89,0-24.22,9.76t-9.32,28q0,19.52,10.32,28.16a36.06,36.06,0,0,0,23.85,8.66q16.36,0,25.46-10.26,5.85-6.45,6.57-12.71h-18A15.35,15.35,0,0,1,349,88.72,14.59,14.59,0,0,1,339.14,92a17.49,17.49,0,0,1-9.78-2.65q-6.69-4.26-7.1-14.85h49.31A93.93,93.93,0,0,0,371,60.49Zm-48.25,1.67a19.05,19.05,0,0,1,4.72-10.7q3.75-3.94,10.58-4a15.33,15.33,0,0,1,10.51,3.73q4.23,3.72,4.72,10.92Z"
        />
        <polygon
          className="ec_logo_letter mobile-hide text-white"
          points="416.09 34.11 435.12 34.11 410.61 104.49 391.92 104.49 367.63 34.11 387.52 34.11 401.63 86.02 416.09 34.11"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M495.37,99.65c-.59-.85-.95-2.46-1.07-4.84q-.12-4.78-.12-8.14V54.37q0-12.72-8.69-17.3a42,42,0,0,0-19.88-4.58q-17.19,0-24.25,9.29-4.44,5.94-5,15.17h17.12A13.47,13.47,0,0,1,456,50.49q2.64-3.23,9-3.23,5.69,0,8.62,1.65t2.93,6q0,3.56-3.82,5.23a27.34,27.34,0,0,1-7.08,1.62l-6.09.77Q449.26,63.85,443.92,67q-9.72,5.81-9.72,18.78,0,10,6,15.47t15.3,5.45a24.86,24.86,0,0,0,13-3.43,36.51,36.51,0,0,0,8.51-7.07c.13,1.6.29,3,.5,4.27a21.36,21.36,0,0,0,1.12,4H498v-2.73A6.39,6.39,0,0,1,495.37,99.65ZM476.52,77.13q-.19,9.23-5.09,12.7a18.07,18.07,0,0,1-10.72,3.49,9.52,9.52,0,0,1-6.23-2.1q-2.57-2.09-2.56-6.8a8.48,8.48,0,0,1,4.19-7.81c1.65-1,4.38-1.82,8.16-2.51l4-.78a27.73,27.73,0,0,0,4.77-1.25,19.43,19.43,0,0,0,3.44-1.78Z"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M496.27,47.76V34.85h9.46L506,23.3l17.3-8.19V34.85h11V47.76h-11V85.1c0,2.89.35,4.7,1.06,5.41s2.86,1.07,6.47,1.07q.81,0,1.71-.06c.6,0,1.19-.1,1.78-.19v13.78l-8.42.37q-12.6.45-17.21-4.53-3-3.17-3-9.77V47.76Z"
        />
        <rect
          className="ec_logo_letter mobile-hide text-white"
          x="539.39"
          y="8.91"
          width="17.96"
          height="17.01"
        />
        <rect
          className="ec_logo_letter mobile-hide text-white"
          x="539.39"
          y="34.11"
          width="17.96"
          height="70.38"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M624.83,43.13Q616.25,32,598.76,32T572.69,43.13q-8.58,11.13-8.59,26.32,0,15.45,8.59,26.42t26.07,11q17.49,0,26.07-11t8.59-26.42Q633.42,54.27,624.83,43.13ZM610.69,85.84q-4.19,5.75-12,5.74t-12-5.74q-4.2-5.75-4.2-16.33t4.2-16.29q4.2-5.7,12-5.71t12,5.71q4.17,5.72,4.17,16.29T610.69,85.84Z"
        />
        <path
          className="ec_logo_letter mobile-hide text-white"
          d="M694.36,38.2Q701,43.91,701,57.14v47.35h-18.2V61.7q0-5.55-1.42-8.52-2.58-5.41-9.85-5.42-8.94,0-12.26,7.94a28.43,28.43,0,0,0-1.73,10.71v38.08H639.84V34.36H657V44.55a30.69,30.69,0,0,1,6.45-7.8q5.45-4.26,13.82-4.26Q687.7,32.49,694.36,38.2Z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
