import { history } from "../../../history";
import axios from "axios";
import { authorizedUser } from "../auth/loginActions";

const URL = process.env.REACT_APP_API_URL;
let token = localStorage.getItem("id_token") || null;
let config = {
  headers: {
    Authorization: `Bearer ${token}`,
  }
};

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

const updateUser = (info, status) => {
  return {
    type: UPDATE_USER_SUCCESS,
    isUpdating: false,
    updatedInformation: info,
    status
  };
};

export const userSettingsActions = newInfo => {
  const USER = JSON.parse(localStorage.user);
  return dispatch => {
    const dataToSend = new FormData();
    Object.keys(newInfo).forEach(key => {
      if (newInfo[key].name !== undefined) {
        dataToSend.append(key, newInfo[key], newInfo[key].name);
      } else {
        dataToSend.append(key, newInfo[key]);
      }
    });

    dataToSend.append("_method", "PATCH");

    axios
      .post(`${URL}admin/users/update/${USER.id}`, dataToSend, config)
      .then(response => {
        let updatedInfo;
        let status;
        if (response.data) {
          status = response.data.status;
          updatedInfo = response.data.data;
          dispatch(updateUser(updatedInfo, status));
          authorizedUser();
          history.push("/");
        }
      })
      .catch(err => console.log(err));
  };
};
