import { history } from "../../../history";
import axios from "axios";
import axiosRetry from "axios-retry";
import setAuthToken from "../../reducers/setAuthToken";

const URL = process.env.REACT_APP_API_URL;

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_RESET = "LOGIN_RESET";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const GET_AUTH_USER = "GET_AUTH_USER";

export const ERROR = "ERROR";

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const requestLogin = creds => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.id_token,
    currentUser: user
  };
};

const loginError = message => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  };
};

const loginReset = () => {
  return {
    type: LOGIN_RESET
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
};

const getAuthorizedUser = authUser => {
  return {
    type: GET_AUTH_USER,
    authUser
  };
};

const getError = (response, message) => {
  return {
    type: ERROR,
    response,
    message
  };
};

export const login = user => {
  return dispatch => {
    dispatch(requestLogin(user));
    axios
      .post(`${URL}auth/login`, {
        email: user.email,
        password: user.password
      })
      .then(response => {
        let loggedInUser;
        let token;
        let expiry;

        if (response.data) {
          loggedInUser = response.data;
          token = loggedInUser.access_token;
          expiry = loggedInUser.expires_in;

          const headers = {
            Authorization: `Bearer ${token}`
          };

          localStorage.setItem("id_token", token);
          localStorage.setItem("expires_in", expiry);
          setAuthToken(token);

          axios
            .get(`${URL}auth/authenticated-user`, {
              headers
            })
            .then(response => {
              loggedInUser = response.data.data;

              localStorage.setItem("user", JSON.stringify(loggedInUser));

              if (loggedInUser) {
                dispatch(receiveLogin(loggedInUser));
                history.push("/");
              }
            })
            .catch(history.push("/login"));
        } else {
          dispatch(loginError(user.message));
          return Promise.reject(user);
        }
      })
      .catch(err => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const authorizedUser = () => {
  let loggedInUser;
  let token;
  let headers;

  return dispatch => {
    token = localStorage.getItem("id_token");
    headers = {
      Authorization: `Bearer ${token}`
    };

    axios
      .get(`${URL}auth/authenticated-user`, {
        headers
      })
      .then(response => {
        loggedInUser = response.data.data;

        localStorage.setItem("user", JSON.stringify(loggedInUser));

        if (loggedInUser) {
          localStorage.setItem("id_token", token);
          dispatch(getAuthorizedUser(loggedInUser));
        }
      })
      .catch(err => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const resetPassword = user => {
  return dispatch => {
    axios
      .post(`${URL}auth/password/reset/send`, {
        email: user.email,
        origin: `${window.location.origin}/forgot-password/`
      })
      .then(response => {
        history.push("/login");
      })
      .catch(err => {
        dispatch(loginError(err.response.data));

        console.log(err.response);
      });
  };
};

export const changePassword = user => {
  return dispatch => {
    axios
      .post(`${URL}auth/password/reset/validate`, {
        email: user.email,
        token: user.token,
        password: user.password
      })
      .then(response => {
        axios
          .post(`${URL}auth/login`, {
            email: user.email,
            password: user.password
          })
          .then(response => {
            let loggedInUser;
            let token;
            let expiry;

            if (response.data) {
              loggedInUser = response.data;
              token = loggedInUser.access_token;
              expiry = loggedInUser.expires_in;

              const headers = {
                Authorization: `Bearer ${token}`
              };

              localStorage.setItem("id_token", token);
              localStorage.setItem("expires_in", expiry);
              setAuthToken(token);

              axios
                .get(`${URL}auth/authenticated-user`, {
                  headers
                })
                .then(response => {
                  loggedInUser = response.data.data;
                  localStorage.setItem("user", JSON.stringify(loggedInUser));

                  if (loggedInUser) {
                    dispatch(receiveLogin(loggedInUser));
                    history.push("/");
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            } else {
              dispatch(loginError(user.message));
              return Promise.reject(user);
            }
          })
          .catch(err => {
            dispatch(loginError(err.response.data));

            console.log(err);
          });
      })
      .catch(err => {
        dispatch(loginError(err.response.data));

        console.log(err.response);
      });
  };
};

export const resetLogin = () => {
  return dispatch => {
    dispatch(loginReset());
  };
};

export const logout = () => {
  return dispatch => {
    dispatch(requestLogout());

    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("user");
    setAuthToken(false);

    dispatch(receiveLogout());

    history.push("/");
  };
};
