import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { logout } from "../../../redux/actions/auth/loginActions";
import NavbarUser from "./NavbarUser";

import Logo from "../menu/vertical-menu/Logo";

const ThemeNavbar = props => {
  const user = props.user.login.currentUser;
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu background-white navbar-shadow navbar-dark fixed-top b-width-none b-b-width-xs border-light-grey",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-end align-items-center"
              id="navbar-mobile"
            >
              {props.horizontal ? (
                <NavLink to="/" className="navbar-brand abs-pos stayleft ml-2">
                  <Logo className="w-104" />
                </NavLink>
              ) : null}
              <NavbarUser
                userName={user.name}
                userImg={user.avatar}
                logout={props.logout}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth
  };
};

export default connect(mapStateToProps, {
  logout
})(ThemeNavbar);
