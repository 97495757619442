const navigationConfig = [
  {
    id: "stream",
    title: "Stream Now",
    type: "item",
    icon: '',
    permissions: ["admin"],
    navLink: "/"
  },
  {
    id: "marketingSpace",
    title: "Marketing Space",
    type: "item",
    icon: '',
    permissions: ["admin"],
    navLink: "/marketing-space"
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: '',
    permissions: ["admin"],
    navLink: "/users",
    parentOf: ["/users/edit/:id"]
  }
];

export default navigationConfig;
